/**
 * @typedef {['add' | 'update' | 'select' | 'delete' | 'close' | 'metadata' | 'change' | 'delete2' | 'cancel']} Events
 */

/**
 * A class that allows the level editor to communicate events to the React-based UI.
 */
export default class EditorEmitter extends EventTarget {
	constructor() {
		super();
	}

	/**
	 * Overrides `addEventListener` to provide JSDoc annotations.
	 * @param {Events} args
	 */
	addEventListener(...args) {
		return super.addEventListener(...args);
	}

	/**
	 * Indicates to the UI the current entity is being added (e.g. the user has clicked the mouse to add an entity).
	 * @param {number} entity A number representing the entity being added. It will always be in the range 0 - 3, inclusive. See `editor.addingEntity`.
	 * @param {{shape: string, x: number, y: number, color: number, index: number?, levelSize: {w: number, h: number}}} data The initial position, shape, color, and / or index of the entity being added.
	 */
	add(entity, data) {
		this.dispatchEvent(new CustomEvent('add', {detail: {entity, ...data}}));
	}

	/**
	 * Indicates to the UI the current entity being added / selected is being updated. This event is constantly emitted when the mouse moves while the user is adding an entity.
	 * @param {{x: number, y: number, w: number, h: number} | {x: number, y: number, r: number} | {x: number, y: number}} data The position and / or size of the entity being added.
	 */
	update(data) {
		this.dispatchEvent(new CustomEvent('update', {detail: data}));
	}

	/**
	 * Indicates to the UI the selection of an entity.
	 * @param {number} entity A number representing the entity being selected. It will always be in the range 0 - 3, inclusive. See `editor.addingEntity`.
	 * @param {{shape: string?, x: number, y: number, w: number?, h: number?, r: number?, color: number?, index: number?, spawnDelay: number?, levelSize: {w: number, h: number}}} data The data of the selected entity.
	 */
	select(entity, data) {
		this.dispatchEvent(new CustomEvent('select', {detail: {entity, ...data}}));
	}

	/**
	 * Indicates to the UI the user has right-clicked the selected entity, causing it to be deleted. This is equivalent to `delete2()`, however is used for **editor-to-UI** communication.
	 */
	delete() {
		this.dispatchEvent(new CustomEvent('delete'));
	}

	/**
	 * Indicates to the UI to close the editor UI.
	 */
	close() {
		this.dispatchEvent(new CustomEvent('close'));
	}

	/**
	 * Indicates to the UI to open the level metadata editor.
	 * @param {{title: string, width: number, height: number, difficulty: number, players: string}} data The level's metadata.
	 */
	metadata(data) {
		this.dispatchEvent(new CustomEvent('metadata', {detail: data}));
	}

	/**
	 * Indicates to the level editor that the user has changed something about the level.
	 * @param {string} type The type of change. Can be either 'entity' (change entity's position / size / shape / etc.) or 'metadata' (change level's metadata).
	 * @param {{shape: string?, color: number?, x: number?, y: number?, w: number?, h: number?, r: number?} | {title: string?, width: number?, height: number?, difficulty: number?, players: string?}} data The data that was changed.
	 */
	change(type, data) {
		this.dispatchEvent(new CustomEvent('change', {detail: {type, ...data}}));
	}

	/**
	 * Indicates to the level editor the user has clicked the delete button, causing the selected entity to be deleted. This is equivalent to `delete()`, however is used for **UI-to-editor** communication.
	 */
	delete2() {
		this.dispatchEvent(new CustomEvent('delete2'));
	}

	/**
	 * Indicates to the level editor to cancel the current operation.
	 */
	cancel() {
		this.dispatchEvent(new CustomEvent('cancel'));
	}
}
