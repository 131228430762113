/**
 * Is the user currently focused on the canvas?
 */
export let foc = false;

/**
 * Is the user's mouse currently pressed?
 */
export let md = false;

/**
 * An object from key names to booleans representing which keys are currently pressed.
 * @type {Object<string, boolean>}
 */
export const keys = {};

/**
 * Register an event listener function for a keyDown event. The `keys` object exposed by this module can be used to determine multiple keys that have been pressed.
 * @param {(event: KeyboardEvent) => *} func
 */
export function onKeyDown(func) {
	const canvas = document.getElementById('canvas');
	canvas.addEventListener('keydown', func);
}

/**
 * Register an event listener function for a keyUp event. The `keys` object exposed by this module can be used to determine multiple keys that have been released.
 * @param {(event: KeyboardEvent) => *} func
 */
export function onKeyUp(func) {
	const canvas = document.getElementById('canvas');
	canvas.addEventListener('keyup', func);
}

/**
 * Register an event listener function for a mouseDown event. The `md` value exposed by this module can be used to determine if the user is holding down their mouse.
 * @param {(event: MouseEvent) => *} func
 */
export function onMouseDown(func) {
	const canvas = document.getElementById('canvas');
	canvas.addEventListener('mousedown', func);
}

/**
 * Register an event listener function for a mouseUp event. The `md` value exposed by this module can be used to determine if the user is holding down their mouse.
 * @param {(event: MouseEvent) => *} func
 */
export function onMouseUp(func) {
	const canvas = document.getElementById('canvas');
	canvas.addEventListener('mouseup', func);
}

const canvas = document.getElementById('canvas');
canvas.addEventListener('contextmenu', event => event.preventDefault());
canvas.addEventListener('keydown', event => {
	if (!foc) return;
	// TODO: allow custom keybinds?
	keys[event.key] = true;
});
canvas.addEventListener('keyup', event => {
	if (!foc) return;
	keys[event.key] = false;
});
canvas.addEventListener('focus', () => foc = true);
canvas.addEventListener('blur', () => foc = false);
canvas.addEventListener('mousedown', () => md = true);
canvas.addEventListener('mouseup', () => md = false);
