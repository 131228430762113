import {api} from '../net/api.js';
import {connection} from '../net/ws.js';
import camera from './camera.js';
import EditorEmitter from './emitters/editor.js';
import {entityToId, Finish, Portal, Spawn, Wall, WallShape} from './entities.js';
import {mouse, state, ui} from './global.js';
import {guide as guideUI} from './guide_ui.js';
import Level, {defaultLevelMetadata} from './level.tsx';
import {keys, onKeyDown as rawKeyDown, onMouseDown, onMouseUp} from './keys.js';
import {localPlayer} from './player.js';
import {text} from './text.js';
import {clamp, luma, openFile, saveFile, shallowEqual} from '../utils.js';

/**
 * A EditorEmitter that can be used to update UI in response to changes in the editor.
 */
export const emitter = new EditorEmitter();

/**
 * Handle key presses that aren't handled by the guide UI.
 * @param {KeyboardEvent} e The key press event.
 */
rawKeyDown(event => {
	if (state !== 2) return;

	switch (event.key) {
		// pressing ESCAPE (cancel add entity operation)
		case 'Escape':
			editor.addingEntity = -1;
			guideUI.setSection('init');
			emitter.close();
			break;

		// pressing + / = or - (changing car index)
		case '+':
		case '=':
			if (editor.car < Math.max(editor.level.spawns.size, editor.level.finishes.size)) {
				++editor.car;
			}
			break;

		case '-':
			if (editor.car > -1) {
				--editor.car;
			}
			break;

		// pressing 1-4 (changing entity)
		default:
			const keyCode = event.key.charCodeAt(0) - 49;
			if (editor.addingEntity === -1) {
				editor.selectedEntity = keyCode >= 0 && keyCode <= 3 ? keyCode : editor.selectedEntity;
			}
			break;
	}
});

// changing position or size of entity being added / selected entity
emitter.addEventListener('change', e => {
	const d = e.detail;
	const key = Object.keys(e.detail).find(key => key !== 'type'); // TODO
	switch (e.detail.type) {
		// changing position / size / shape / etc. of entity being added / selected
		case 'entity':
			if (editor.addingEntity === 0) {
				switch (key) {
					case 'shape':
						editor.shape = d.shape;
						break;

					case 'color':
						editor.lastPortalColor = d.color;
						break;
				}

				// can't modify position / size here as that's simply decided by the user when they move their mouse
			} else if (editor.selectedEntityObject) {
				switch (key) {
					case 'shape':
						editor.selectedEntityObject.convertShape(d.shape);
						emitter.update(editor.selectedEntityObject.s.data);
						break;

					case 'color':
						editor.selectedEntityObject.color = d.color;
						editor.lastPortalColor = d.color;
						break;

					case 'x':
					case 'y':
					case 'w':
					case 'h':
					case 'r':
						if (editor.selectedEntityObject instanceof Wall) {
							editor.selectedEntityObject.s.data[key] = d[key];
						} else {
							editor.selectedEntityObject[key] = d[key];
						}
						break;

					case 'index':
						if (editor.selectedEntityObject instanceof Finish) {
							editor.level.finishes.set(editor.selectedEntityObject, d.index);
						} else if (editor.selectedEntityObject instanceof Spawn) {
							editor.level.spawns.set(editor.selectedEntityObject, d.index);
						}
						break;

					case 'spawnDelay':
						editor.selectedEntityObject.t = e.detail.spawnDelay;
				}
			}
			break;

		// changing level metadata
		case 'metadata':
			editor.level.info[key] = d[key];
			break;
	}
});

// deleting selected entity (same as right clicking on it)
emitter.addEventListener('delete2', _ => {
	if (editor.selectedEntityObject) {
		editor.level.deleteEntity(editor.selectedEntityObject);
		editor.selectedEntityObject = null;
	}
});

emitter.addEventListener('cancel', () => {
	// same as pressing Escape
	if (editor.addingEntity >= 0) {
		editor.addingEntity = -1;
		guideUI.setSection('init');
	} else { // deselect entity
		editor.selectedEntityObject = null;
	}
});

/**
 * Handle a key down event.
 * @param {string} key The key that was pressed.
 */
async function onKeyDown(key) {
	switch (key) {
		// pressing E (export the level)
		case 'e':
			saveFile('level.json', 'text/plain', JSON.stringify(editor.level.toLevel()));
			break;

		// pressing L (load a level)
		case 'l':
			openFile(['json'], 'txt')
				.then(file => {
					keys['l'] = false; // can't detect keyUp when file dialog opens

					try {
						const level = Level.parse(JSON.parse(file));
						editor.level.fromLevel(level);
						editor.level.centerCamera(false);
					} catch (e) {
						console.error(e);
						guideUI.setHint('please upload a valid level file');
					}
				});
			break;

		// pressing T (playtest)
		case 't':
			// validate level
			const error = editor.level.validate();
			if (typeof error === 'string') {
				guideUI.setHint(error);
				return;
			}

			editor.addingEntity = -1;
			editor.selectedEntityObject = null;
			guideUI.setSection('init');
			emitter.close();

			const level = editor.level.toLevel();

			const {game} = await import('./game.js');
			game.playtest = true;
			game.initData.level = level;
			game.initData.gameMode = 0;
			connection.gameMode = 0; // TODO not clean

			guideUI.enabled = false;
			api().auth.solo.playtest.post(level)
				.then(() => guideUI.enabled = true)
				.catch(() => guideUI.enabled = true);
			break;

		// pressing M (opening level metadata ui)
		case 'm':
			emitter.metadata(editor.level.info);
			break;
	}
}

onMouseDown(event => {
	if (state !== 2) return;
	if (event.button === 0) { // left click
		if (editor.addingEntity < 0) { // select an existing element, or start process of adding an entity to the grid
			const e = editor.level.entityUnderCursor(editor.mouseWithGrid);
			if (e) { // select an existing entity
				editor.selectedEntityObject = e;
				editor.selectedEntityCell = {x: editor.cell.x, y: editor.cell.y};
				emitter.select(entityToId(e), {
					shape: e.s?.type || null, // walls only
					x: e instanceof Wall ? e.s.data.x : e.x,
					y: e instanceof Wall ? e.s.data.y : e.y,
					w: (e instanceof Wall ? e.s.data.w : e.w) || null,
					h: (e instanceof Wall ? e.s.data.h : e.h) || null,
					r: (e instanceof Wall ? e.s.data.r : e.r) || null,
					color: e.color ?? null,
					index: e instanceof Finish ? editor.level.finishes.get(e)
						: e instanceof Spawn ? editor.level.spawns.get(e)
						: null,
					spawnDelay: e.t ?? null,
					levelSize: {w: editor.level.info.width, h: editor.level.info.height},
				});
			} else { // start adding an entity
				editor.selectedEntityObject = null;
				editor.startCell = {x: editor.cell.x, y: editor.cell.y};
				editor.addingEntity = editor.selectedEntity;
				guideUI.setSection('entity');
				emitter.add(editor.addingEntity, {
					shape: editor.shape,
					x: editor.startCell.x * editor.cellSize,
					y: editor.startCell.y * editor.cellSize,
					color: editor.lastPortalColor,
					index: editor.addingEntity === 2 || editor.addingEntity === 3 ? editor.level.nextIndex(editor.addingEntity) : null,
					levelSize: {w: editor.level.info.width, h: editor.level.info.height},
				});
			}
		}
	} else if (event.button === 2) { // right click
		if (editor.addingEntity >= 0) { // adding an entity to the grid
			const added = editor.level.addEntity(editor.addingEntity, editor.entityBound);
			editor.addingEntity = -1;
			guideUI.setSection('init');
			editor.selectedEntityObject = added;
			emitter.select(entityToId(added), {
				shape: added.s?.type || null, // walls only
				x: added instanceof Wall ? added.s.data.x : added.x,
				y: added instanceof Wall ? added.s.data.y : added.y,
				w: (added instanceof Wall ? added.s.data.w : added.w) || null,
				h: (added instanceof Wall ? added.s.data.h : added.h) || null,
				r: (added instanceof Wall ? added.s.data.r : added.r) || null,
				color: added.color ?? null,
				index: added instanceof Finish ? editor.level.finishes.get(added)
					: added instanceof Spawn ? editor.level.spawns.get(added)
					: null,
				spawnDelay: added.t ?? null,
				levelSize: {w: editor.level.info.width, h: editor.level.info.height},
			});
		} else { // delete an entity from the grid; detect clicks on walls, finishes, and spawns
			if (editor.level.deleteUnderCursor(editor.mouseWithGrid) && editor.selectedEntityObject) {
				emitter.delete();
			}
		}
	}
});

onMouseUp(event => {
	if (state !== 2) return;
	// stop dragging entity
	if (editor.selectedEntityCell) {
		editor.selectedEntityCell = null;
	}
});

/**
 * Information on the level editor state.
 */
export const editor = {
	/**
	 * The level that is currently being edited. It mimics the structure of the `Level` class, but contains tools that are relevant to the editor.
	 */
	level: {
		/**
		 * The metadata of the level.
		 */
		info: defaultLevelMetadata(),

		/**
		 * The walls in the level. (ID: 0)
		 * @type {Wall[]}
		 */
		walls: [],

		/**
		 * The portals in the level. For each portal in this array, there must be another portal with the same color. (ID: 1)
		 * @type {Portal[]}
		 */
		portals: [],

		/**
		 * The finishes in the level, mapped to their spawn index. They are mapped this way because the user can choose any index they'd like while editing. When validated, all indexes must be unique and must map to a spawn. (ID: 2)
		 * @type {Map<Finish, number>}
		 */
		finishes: new Map(),

		/**
		 * The spawns in the level, mapped to their spawn index. They are mapped this way because the user can choose any index they'd like while editing. When validated, all indexes must be unique and must map to a finish. (ID: 3)
		 * @type {Map<Spawn, number>}
		 */
		spawns: new Map(),

		/**
		 * Center the camera on the level.
		 * @param {boolean} [instant=true] Whether to instantly center the camera (default, true) or to animate it.
		 */
		centerCamera(instant = true) {
			const x = this.info.width / 2 * editor.cellSize;
			const y = this.info.height / 2 * editor.cellSize;
			if (instant) {
				camera.teleport(x, y);
			} else {
				camera.setPos(x, y);
			}
		},

		/**
		 * Validate the level for playtesting, returning `true` if it is valid, and a string with the error if not.
		 */
		validate() {
			// portals must have one other portal with the same color
			const portalColors = new Map();
			for (const portal of this.portals) {
				if (portalColors.has(portal.color)) {
					if (portalColors.get(portal.color) === 1) { // this is the second portal, so far so good
						portalColors.set(portal.color, 2);
					} else { // this is the third portal, fail
						return `portals (#${portal.color.toString(16)}) can only have one other portal with the same color`;
					}
				} else {
					portalColors.set(portal.color, 1);
				}
			}

			// check if a portal doesn't have a pair
			for (const [color, count] of portalColors) {
				if (count === 1) {
					return `portal (#${color.toString(16)}) doesn't have a pair`;
				}
			}

			// validate finishes and spawns
			if (this.spawns.size === 0 && this.finishes.size === 0) {
				return 'there must be at least one spawn and finish';
			} else if (this.spawns.size !== this.finishes.size) {
				return `number of spawns (${this.spawns.size}) must equal number of finishes (${this.finishes.size})`;
			}

			// validate finish and spawn indexes are unique
			const finishIndexes = new Set();
			const spawnIndexes = new Set();

			for (const index of this.finishes.values()) {
				if (finishIndexes.has(index)) {
					return `there are multiple finishes with same index (${index})`;
				} else {
					finishIndexes.add(index);
				}
			}

			for (const index of this.spawns.values()) {
				if (spawnIndexes.has(index)) {
					return `there are multiple spawns with same index (${index})`;
				} else {
					spawnIndexes.add(index);
				}
			}

			const finishes = [];
			const spawns = [];

			for (const [finish, index] of this.finishes) finishes[index] = finish;
			for (const [spawn, index] of this.spawns) spawns[index] = spawn;

			for (let i = 0; i < finishes.length; ++i) { // guaranteed same length
				if (!finishes[i] && !spawns[i]) {
					return `missing finish and spawn at index (${i})`;
				} else if (!finishes[i]) {
					return `spawn (${i}) has no corresponding finish`;
				} else if (!spawns[i]) {
					return `finish (${i}) has no corresponding spawn`;
				}
			}

			return true;
		},

		/**
		 * Convert this level to a `Level` object. It is assumed that the level is valid.
		 */
		toLevel() {
			const level = new Level();
			level.info = Object.assign({}, this.info);
			level.walls = this.walls;
			level.portals = this.portals;

			for (const [finish, index] of this.finishes) {
				level.finishes[index] = finish;
			}

			for (const [spawn, index] of this.spawns) {
				level.spawns[index] = spawn;
			}

			level.generateSprite();

			return level;
		},

		/**
		 * Apply the given `Level` object to the level editor level.
		 * @param {Level} level The level to convert.
		 */
		fromLevel(level) {
			this.info = Object.assign({}, level.info);
			this.walls = level.walls;
			this.portals = level.portals;

			this.finishes = new Map();
			this.spawns = new Map();

			for (let i = 0; i < level.finishes.length; ++i) {
				this.finishes.set(level.finishes[i], i);
			}

			for (let i = 0; i < level.spawns.length; ++i) {
				this.spawns.set(level.spawns[i], i);
			}
		},

		/**
		 * Returns the next index for a finish or spawn in the level.
		 * @param {number} id The ID of the entity to get the next index for. Should be either 2 (finish) or 3 (spawn).
		 */
		nextIndex(id) {
			const iter = id === 2 ? this.finishes.values()
				: id === 3 ? this.spawns.values() : null;

			if (iter) {
				const arr = Array.from(iter);
				let search = 0;

				// repeatedly loop until we find an index that isn't in use
				while (arr.includes(search)) ++search;

				return search;
			} else {
				return null;
			}
		},

		/**
		 * Adds the entity with the given ID to the level, then returns the entity. The index of existing entities is preserved when adding finishes and spawns.
		 * @param {number} id The ID of the entity to add. See `editor.addingEntity`.
		 * @param {{x: number, y: number, w: number, h: number} | {x: number, y: number, r: number} | {x: number, y: number}} bound The position and / or size of the entity.
		 * @returns {Wall | Portal | Finish | Spawn} The entity that was added.
		 */
		addEntity(id, bound) {
			if (id === 3) { // spawns
				var entity = new Spawn(bound.x, bound.y, 0);
				this.spawns.set(entity, this.nextIndex(id));
			} else { // finishes, portals, walls
				switch (id) {
					case 0: // wall
						var entity = new Wall(new WallShape(editor.shape, bound));
						this.walls.push(entity);
						break;

					case 1: // portal
						var entity = new Portal(editor.lastPortalColor, bound.x, bound.y, bound.w, bound.h);
						this.portals.push(entity);
						break;

					case 2: // finish
						var entity = new Finish(bound.x, bound.y, bound.w, bound.h);
						this.finishes.set(entity, this.nextIndex(id));
						break;
				}
			}

			return entity;
		},

		/**
		 * Returns the entity underneath the mouse cursor. This can be used for selection of entities in the level editor.
		 *
		 * Entities are returned in this priority: walls, portals, finishes, spawns.
		 */
		entityUnderCursor() {
			return this.walls.find(wall => wall.containsPoint(editor.mouseWithGrid))
				|| this.portals.find(portal => portal.containsPoint(editor.mouseWithGrid))
				|| [...this.finishes.keys()].find(finish => finish.containsPoint(editor.mouseWithGrid))
				|| [...this.spawns.keys()].find(spawn => spawn.containsPoint(editor.mouseWithGrid));
		},

		/**
		 * Returns the index of the given entity, or null if the entity has no index
		 * @param {Finish | Spawn} entity The entity to get the index of.
		 */
		indexOf(entity) {
			return entity instanceof Finish ? this.finishes.get(entity)
				: entity instanceof Spawn ? this.spawns.get(entity) : null;
		},

		/**
		 * Deletes the entity underneath the mouse cursor. Returns the entity that was deleted.
		 *
		 * Entities are deleted in this priority: walls, portals, finishes, spawns.
		 */
		deleteUnderCursor() {
			const wall = this.walls.findIndex(wall => wall.containsPoint(editor.mouseWithGrid));
			if (wall >= 0) return this.walls.splice(wall, 1)[0];

			const portal = this.portals.findIndex(portal => portal.containsPoint(editor.mouseWithGrid));
			if (portal >= 0) return this.portals.splice(portal, 1)[0];

			for (const finish of this.finishes.keys()) {
				if (finish.containsPoint(editor.mouseWithGrid)) {
					this.finishes.delete(finish);
					return finish;
				}
			}

			for (const spawn of this.spawns.keys()) {
				if (spawn.containsPoint(editor.mouseWithGrid)) {
					this.spawns.delete(spawn);
					return spawn;
				}
			}
		},

		/**
		 * Given the entity to delete, deletes it from the level.
		 * @param {Wall | Portal | Finish | Spawn} entity The entity to delete.
		 */
		deleteEntity(entity) {
			if (entity instanceof Wall) {
				this.walls.splice(this.walls.indexOf(entity), 1);
			} else if (entity instanceof Portal) {
				this.portals.splice(this.portals.indexOf(entity), 1);
			} else if (entity instanceof Finish) {
				this.finishes.delete(entity);
			} else if (entity instanceof Spawn) {
				this.spawns.delete(entity);
			}
		},
	},

	/**
	 * The currently selected spawn index for the Classic game mode. A value of -1 indicates no spawn is selected.
	 */
	car: -1,

	/**
	 * The exact position of the mouse in relation to the level grid, where the top left corner is (0, 0).
	 *
	 * This value is computed automatically.
	 */
	mouseWithGrid: {x: 0, y: 0},

	/**
	 * The coordinates of the cell the mouse is currently over.
	 *
	 * This value is computed automatically.
	 */
	cell: {x: 0, y: 0},

	/**
	 * When adding an entity (wall, portal, etc.), these are the coordinates of the cell the user clicked on.
	 */
	startCell: {x: 0, y: 0},

	/**
	 * When adding a wall, this is the wall's shape. Can be either 'rect' or 'circle'.
	 *
	 * The value of this field affects the types of `entityBound` and `lastEntityBound` when adding walls.
	 */
	shape: 'rect',

	/**
	 * When adding a wall, portal, or finish, this describes the entity's position and size when it is added. When adding a spawn entity, this describes the spawn's position.
	 * @type {{x: number, y: number, w: number, h: number} | {x: number, y: number, r: number} | {x: number, y: number}}
	 */
	entityBound: {x: 0, y: 0, w: 0, h: 0},

	/**
	 * The last `entityBound` that was reported to the UI. This is compared with `entityBound` to determine if the UI should be notified of a change.
	 * @type {{x: number, y: number, w: number, h: number} | {x: number, y: number, r: number} | {x: number, y: number}}
	 */
	lastEntityBound: {x: 0, y: 0, w: 0, h: 0},

	/**
	 * The color of the last portal that was added / being added. This is used to draw the portal entity indicator in the bottom-right corner.
	 */
	lastPortalColor: 0x761bd1, // this is an arbitrary color

	/**
	 * The width and height of each cell.
	 */
	cellSize: 10,

	/**
	 * The currently selected entity. "Selected" means that the user has chosen the entity to add with the number keys, and is ready to begin the adding process. Can be one of four values:
	 *
	 * - 0: wall
	 * - 1: portal
	 * - 2: finish
	 * - 3: spawn
	 */
	selectedEntity: 0,

	/**
	 * The entity currently being added. Can be one of five values:
	 *
	 * - -1: no entity is being added
	 * - 0: wall
	 * - 1: portal
	 * - 2: finish
	 * - 3: spawn
	 */
	addingEntity: -1,

	/**
	 * The entity that is currently selected for modification. Note that this is entirely different from `selectedEntity`, and it refers to entities that have already been placed.
	 * @type {Wall | Portal | Finish | Spawn}
	 */
	selectedEntityObject: null,

	/**
	 * The coordinates of the cell the user clicked when selecting an added entity.
	 *
	 * This is used to determine the object's offset when moving it around.
	 */
	selectedEntityCell: null,
};

/**
 * Given the starting and selected end cell of an entity, calculate the bounds of the rectangle that can be used to store and display the entity on the editor.
 * @param {{x: number, y: number}} startCell The starting cell of the entity.
 * @param {{x: number, y: number}} endCell The ending cell of the entity.
 */
function findEntityBox(startCell, endCell) {
	return {
		x: (startCell.x + endCell.x + 1) / 2 * editor.cellSize,
		y: (startCell.y + endCell.y + 1) / 2 * editor.cellSize,
		w: (Math.abs(endCell.x - startCell.x) + 1) * editor.cellSize,
		h: (Math.abs(endCell.y - startCell.y) + 1) * editor.cellSize,
	};
}

/**
 * Initialize the editor's guide UI.
 */
export function initEditorGuide() {
	guideUI.setActions('init', [
		['e', {description: 'export'}],
		['l', {description: 'load'}],
		['t', {description: 'playtest'}],
		['m', {description: 'level metadata'}],
	]);

	// user has fewer actions when adding an entity
	guideUI.setActions('entity', [
		['e', {description: 'export'}],
		['l', {description: 'load'}],
	]);

	guideUI.setSection('init');
	guideUI.reset();

	guideUI.callbacks.keyDown = onKeyDown;
	guideUI.callbacks.keyUp = null;
}

/**
 * Function called while in the editor (state === 2).
 */
export function updateEditor() {
	/**
	 * Half the width and height of the viewport.
	 */
	const half = {
		width: window.innerWidth / 2,
		height: window.innerHeight / 2
	};

	/**
	 * The width and height of the level in pixels.
	 */
	const levelSize = {
		width: editor.cellSize * editor.level.info.width,
		height: editor.cellSize * editor.level.info.height,
	};
	camera.setBounds(0, levelSize.width, 0, levelSize.height);

	// move the camera to its actual position
	camera.move();
	camera.readInput();

	// draw external walls outside the level
	camera.graphics
		.beginFill(0, 0.7)
		.drawRect(-half.width, -half.height, levelSize.width + window.innerWidth, levelSize.height + window.innerHeight)
		.beginHole()
		.drawRect(0, 0, levelSize.width, levelSize.height)
		.endHole()
		.endFill();

	camera.graphics.lineStyle(1, 0);

	// draw the grid
	for (let i = 0; i <= editor.level.info.width; ++i) {
		const x = i * editor.cellSize;
		camera.graphics
			.moveTo(x, 0)
			.lineTo(x, levelSize.height);
	}

	for (let i = 0; i <= editor.level.info.height; ++i) {
		const y = i * editor.cellSize;
		camera.graphics
			.moveTo(0, y)
			.lineTo(levelSize.width, y);
	}

	camera.graphics._lineStyle.reset(); // TODO: better way?

	// draw walls and other objects over the grid
	for (let i = 0; i < editor.level.walls.length; ++i) {
		const wall = editor.level.walls[i];
		wall.draw(camera.graphics);
	}

	// draw all finishes and spawns and highlight the selected ones
	// also draw spawn indexes on finishes
	for (const [finish, i] of editor.level.finishes) {
		const selected = editor.car < 0 || editor.car === i;
		finish.drawInEditor(camera.graphics, camera.shadowedGraphics, i, selected);
	}

	// draw all portals without a glow effect
	for (let i = 0; i < editor.level.portals.length; ++i) {
		const portal = editor.level.portals[i];
		portal.draw(camera.graphics);
	}

	// show which tile is highlighted
	const translation = {x: half.width - camera.pos.display.x, y: half.height - camera.pos.display.y};
	const {x, y} = mouse;
	editor.mouseWithGrid = {
		x: x - translation.x,
		y: y - translation.y,
	};

	editor.cell = {
		x: clamp(Math.floor(editor.mouseWithGrid.x / editor.cellSize), 0, editor.level.info.width - 1),
		y: clamp(Math.floor(editor.mouseWithGrid.y / editor.cellSize), 0, editor.level.info.height - 1),
	};

	/**
	 * The coordinates of the currently moused-over cell, in units of pixels instead of cells.
	 */
	const mouseCell = {
		x: editor.cell.x * editor.cellSize,
		y: editor.cell.y * editor.cellSize,
	};

	// check if the user is dragging an entity around
	if (editor.selectedEntityCell) {
		const entity = editor.selectedEntityObject;
		const cell = editor.cell;
		const offset = {
			x: cell.x - editor.selectedEntityCell.x,
			y: cell.y - editor.selectedEntityCell.y,
		};

		// no need to update ui if the entity hasn't moved
		if (offset.x !== 0 || offset.y !== 0) {
			if (entity instanceof Wall) {
				entity.s.data.x += offset.x * editor.cellSize;
				entity.s.data.y += offset.y * editor.cellSize;
				emitter.update({x: entity.s.data.x, y: entity.s.data.y});
			} else {
				entity.x += offset.x * editor.cellSize;
				entity.y += offset.y * editor.cellSize;
				emitter.update({x: entity.x, y: entity.y});
			}

			editor.selectedEntityCell = cell;
		}
	}

	camera.graphics
		.beginFill(0xffffff, 0)
		.lineStyle(1, 0xffffff)
		.drawRect(mouseCell.x, mouseCell.y, editor.cellSize, editor.cellSize)
		._lineStyle.reset(); // TODO: better way?

	for (const [spawn, i] of editor.level.spawns) {
		const selected = editor.car < 0 || editor.car === i;
		spawn.drawInEditor(camera.graphics, camera.shadowedGraphics, localPlayer, i, selected);
	}

	// when adding new entities, draw the preview of the blocks
	if (editor.addingEntity >= 0) {
		if (editor.addingEntity === 3) { // adding spawn
			editor.entityBound = {
				x: mouseCell.x + editor.cellSize / 2,
				y: mouseCell.y + editor.cellSize / 2,
			};

			camera.graphics
				.beginFill(localPlayer.color, 0.47)
				.lineStyle(1, 0)
				.drawCircle(editor.entityBound.x, editor.entityBound.y, 15)
				.endFill();
		} else if (editor.addingEntity === 0 && editor.shape === 'circle') { // adding circular wall
			editor.entityBound = {
				x: editor.startCell.x * editor.cellSize + editor.cellSize / 2,
				y: editor.startCell.y * editor.cellSize + editor.cellSize / 2,
				r: Math.hypot(editor.cell.x - editor.startCell.x, editor.cell.y - editor.startCell.y) * editor.cellSize + editor.cellSize / 2,
			};

			camera.graphics
				.beginFill(0, 0.47)
				.lineStyle(1, 0)
				.drawCircle(editor.entityBound.x, editor.entityBound.y, editor.entityBound.r)
				.endFill();

			text.dimen.center.text = editor.entityBound.r.toFixed(2);
			text.dimen.center.x = editor.cell.x * editor.cellSize + editor.cellSize / 2;
			text.dimen.center.y = editor.cell.y * editor.cellSize + editor.cellSize / 2;

			const angle = Math.atan2(editor.cell.y - editor.startCell.y, editor.cell.x - editor.startCell.x);
			text.dimen.center.anchor.set(-0.7 * Math.cos(angle) + 0.5, -0.7 * Math.sin(angle) + 0.5);

			camera.shadowedGraphics.addChild(text.dimen.center);
		} else { // adding rectangular wall, portal, or finish
			const box = findEntityBox(editor.startCell, editor.cell);
			editor.entityBound = box;
			camera.graphics
				.beginFill(
					editor.addingEntity === 0 ? 0
						: editor.addingEntity === 1 ? editor.lastPortalColor
						: 0xa500,
					0.47
				)
				.lineStyle(1, 0)
				.drawRect(box.x - box.w / 2, box.y - box.h / 2, box.w, box.h)
				.endFill();

			text.dimen.top.text = text.dimen.bottom.text = box.w;
			text.dimen.left.text = text.dimen.right.text = box.h;
			text.dimen.top.x = text.dimen.bottom.x = box.x;
			text.dimen.left.y = text.dimen.right.y = box.y;

			text.dimen.top.y = box.y - box.h / 2 - text.dimen.top.height / 6;
			text.dimen.bottom.y = box.y + box.h / 2 + text.dimen.bottom.height / 6;
			text.dimen.left.x = box.x - box.w / 2;
			text.dimen.right.x = box.x + box.w / 2;

			camera.shadowedGraphics.addChild(text.dimen.top, text.dimen.bottom, text.dimen.left, text.dimen.right);
		}

		if (!shallowEqual(editor.entityBound, editor.lastEntityBound)) {
			emitter.update(editor.entityBound);
			editor.lastEntityBound = editor.entityBound;
		}
	}

	// draw keybindings
	guideUI.update();
	guideUI.draw(ui);

	// if cursor is behind the start guide text, fade it out a bit
	const guideBox = guideUI.boundingBox;
	guideUI.fade = x > 0 && x < guideBox.right && y > guideBox.top && y < window.innerHeight;

	// draw entity indicators in the bottom right
	// whether the indicators should be highlighted or not
	const wallOpacity = editor.addingEntity <= 0 ?
		(editor.selectedEntity === 0 ? 1 : 0.4) : 0.2;
	const portalOpacity = editor.addingEntity === 1 || editor.addingEntity < 0 ?
		(editor.selectedEntity === 1 ? 1 : 0.4) : 0.2;
	const finishOpacity = editor.addingEntity === 2 || editor.addingEntity < 0 ?
		(editor.selectedEntity === 2 ? 1 : 0.4) : 0.2;
	const spawnOpacity = editor.addingEntity === 3 || editor.addingEntity < 0 ?
		(editor.selectedEntity === 3 ? 1 : 0.4) : 0.2;

	ui.beginFill(0, wallOpacity)
		.lineStyle(1, 0, wallOpacity)
		.drawRect(window.innerWidth - 240, window.innerHeight - 95, 40, 40)
		.beginFill(editor.lastPortalColor, portalOpacity)
		.lineStyle(1, 0, portalOpacity)
		.drawRect(window.innerWidth - 190, window.innerHeight - 95, 40, 40)
		.beginFill(0xa500, finishOpacity)
		.lineStyle(1, 0, finishOpacity)
		.drawRect(window.innerWidth - 140, window.innerHeight - 95, 40, 40)
		.beginFill(localPlayer.color, spawnOpacity)
		.lineStyle(1, 0, spawnOpacity)
		.drawCircle(window.innerWidth - 70, window.innerHeight - 75, 20)
		.endFill()
		._lineStyle.reset(); // TODO: better way?

	// change the color of the '4' on the spawn indicator based on how bright or dark the localPlayer color is
	const brightLocalColor = luma(localPlayer.color) > 80;

	const [wall, portal, finish, spawn] = text.entityKey;
	wall.alpha = wallOpacity;
	portal.alpha = portalOpacity;
	finish.alpha = finishOpacity;
	spawn.alpha = spawnOpacity;
	spawn.style.fill = brightLocalColor ? 0 : 0xffffff;

	wall.x = window.innerWidth - 220;
	portal.x = window.innerWidth - 170;
	finish.x = window.innerWidth - 120;
	spawn.x = window.innerWidth - 70;
	wall.y = portal.y = finish.y = spawn.y = window.innerHeight - 75;

	ui.addChild(...text.entityKey);

	// show the currently selected spawn index
	text.selected.text = `Index: ${editor.car < 0 ? 'all' : editor.car}`;
	text.selected.x = window.innerWidth - 145;
	text.selected.y = window.innerHeight - 35;
	ui.addChild(text.selected);
}
